export default function menuDropdown() {
  const carots = document.querySelectorAll(".menu-carot"); // eslint-disable-line
  const carotsArray = [...carots]; // converts NodeList to Array

  if (carotsArray.length < 1) {
    return;
  }

  const menuClass = "menu-open";

  carotsArray.forEach((element) => {
    element.addEventListener("click", () => {
      carotsArray.forEach((e) => {
        if (e.parentElement !== element.parentElement) {
          e.parentElement.classList.remove(menuClass);
        }
      });

      element.parentElement.classList.toggle(menuClass);
    });
  });
}
