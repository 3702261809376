export default function mobileShopSidebar() {
  const menu = document.getElementById("mobile-icon");

  if (!menu) {
    return;
  }

  menu.addEventListener("click", () => {
    document.body.classList.toggle("shop-sidebar-active");
  });
}
