export default function selects() {
  // Do not Executes on WooCheckout
  if (document.body.classList.contains("woocommerce-checkout")) {
    return;
  }

  const allSelects = document.querySelectorAll("select");

  if (!allSelects) {
    return;
  }

  allSelects.forEach((element) => {
    const wrapper = document.createElement("div");
    wrapper.classList.add("custom-select");

    element.parentNode.insertBefore(wrapper, element);

    wrapper.appendChild(element);
  });
}
