const openModal = (modalWrapper) => {
  modalWrapper.classList.add("show");
};

const closeModal = (modalWrapper) => {
  modalWrapper.classList.remove("show");
};

const showAndHideClipsSections = (clipsSections, selectedClip) => {
  clipsSections.forEach((element) => {
    console.log("element, ", element, selectedClip);
    if (element.id !== selectedClip) {
      element.style.display = "none"; // eslint-disable-line
    } else {
      element.style.display = "grid"; // eslint-disable-line
    }
  });
};

export default function clipsModal() {
  const modalWrapper = document.getElementById("clips-modal-wrapper");
  const closeBtn = document.querySelector(".clips-modal-close");

  const clipsButtons = document.querySelectorAll(
    ".single-clip-wrapper .single-clip-button"
  );

  const clipsSections = document.querySelectorAll(
    ".clips-section-wrapper .clips-single-section"
  );

  if (!modalWrapper || !closeBtn || !clipsButtons || !clipsSections) {
    return;
  }

  [...clipsButtons].forEach((e) => {
    e.addEventListener("click", () => {
      const selectedBtn = e.querySelector("span");
      const selectedClip = selectedBtn.dataset.clip;

      openModal(modalWrapper);
      showAndHideClipsSections(clipsSections, selectedClip);
    });
  });

  closeBtn.addEventListener("click", () => {
    closeModal(modalWrapper);
  });
}
