import "../scss/theme.scss";

import hamburgerMenu from "./components/hamburger-menu";
import menuDropdown from "./components/menuDropdown";
// import mixitupPosts from "./components/mixitupPosts";
// import anchorsMenu from "./components/anchorsMenu";
// import phoneClick from "./components/phoneClick";
import mobileShopSidebar from "./components/mobileShopSidebar";
// import scrollReveal from "./components/scrollReveal";
import backToTop from "./components/backToTop";
import modal from "./components/modal";
import clipsModal from "./components/clipsModal";
import selects from "./components/selects";

// wait until the window is fully loaded to run js
window.addEventListener("load", () => {
  // eslint-disable-line
  hamburgerMenu();
  menuDropdown();
  //  mixitupPosts();
  //  phoneClick();
  //  anchorsMenu();
  mobileShopSidebar();
  //  scrollReveal();
  backToTop();
  modal();
  clipsModal();
  selects();
});
